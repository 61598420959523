// src/components/PriceComparisonModal.js
import React, { useState } from 'react';
import './PriceComparisonModal.css';

const PriceComparisonModal = ({ prices, onClose }) => {
  const [item1, setItem1] = useState('');
  const [quantity1, setQuantity1] = useState(1);
  const [item2, setItem2] = useState('');
  const [results, setResults] = useState(null);

  const handleItem1Change = (event) => {
    setItem1(event.target.value);
  };

  const handleQuantity1Change = (event) => {
    setQuantity1(event.target.value);
  };

  const handleItem2Change = (event) => {
    setItem2(event.target.value);
  };

  const calculateComparison = () => {
    const selectedItem1 = prices.find(p => p.id === item1);
    const selectedItem2 = prices.find(p => p.id === item2);

    if (selectedItem1 && selectedItem2) {
      const totalValue1 = parseFloat(selectedItem1.price) * parseFloat(quantity1);
      const equivalentQuantity2 = totalValue1 / parseFloat(selectedItem2.price);
      const differenceInCredits = totalValue1 - (parseFloat(selectedItem2.price) * equivalentQuantity2);

      setResults({
        totalValue1,
        equivalentQuantity2: equivalentQuantity2,
        differenceInCredits,
        differenceInHCSofas: differenceInCredits / parseFloat(selectedItem2.worthInHCSofas),
        differenceInColaMachines: differenceInCredits / parseFloat(selectedItem2.worthInColaMachines),
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Calculate Price Comparison</h2>
        <div className="item-row">
          <select value={item1} onChange={handleItem1Change}>
            <option value="">Select Item</option>
            {prices.map(price => (
              <option key={price.id} value={price.id}>{price.name}</option>
            ))}
          </select>
          <input
            type="number"
            value={quantity1}
            onChange={handleQuantity1Change}
            min="1"
          />
        </div>
        <div className="item-row">
          <select value={item2} onChange={handleItem2Change}>
            <option value="">Select Item</option>
            {prices.map(price => (
              <option key={price.id} value={price.id}>{price.name}</option>
            ))}
          </select>
        </div>
        <button onClick={calculateComparison} className="calculate-button">Calculate</button>
        {results && (
          <div className="results">
            <p>With {quantity1} of {prices.find(p => p.id === item1).name}:</p>
            <p>You can buy {results.equivalentQuantity2.toFixed(2)} of {prices.find(p => p.id === item2).name}</p>
            {results.differenceInCredits !== 0 && (
              <p>Difference in Habbo Credits: {results.differenceInCredits.toFixed(2)}</p>
            )}
            {results.differenceInHCSofas !== 0 && (
              <p>Difference in HC Sofas: {results.differenceInHCSofas.toFixed(2)}</p>
            )}
            {results.differenceInColaMachines !== 0 && (
              <p>Difference in Cola Machines: {results.differenceInColaMachines.toFixed(2)}</p>
            )}
          </div>
        )}
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    </div>
  );
};

export default PriceComparisonModal;
