// src/components/Header.js
import React from 'react';
import './Header.css';
import habboCharacter from '../assets/Habbo-Character.png';

const Header = () => {
  return (
    <header className="header">
      <img src={habboCharacter} alt="Habbo Character" className="habbo-character" />
      <h1>Habbo Origins Rare Prices</h1>
    </header>
  );
};

export default Header;
