// src/App.js
import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import PriceList from './components/PriceList';
import Footer from './components/Footer';
import News from './components/News';
import { auth, signIn, signOutUser } from './components/firebase';
import './App.css';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log("User state changed:", user); // Debug log
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <News />
        <PriceList user={user} onSignIn={signIn} onSignOut={signOutUser} />
      </div>
      <Footer />
    </div>
  );
}

export default App;
