// src/components/PriceList.js
import React, { useState, useEffect } from 'react';
import PriceItem from './PriceItem';
import PriceComparisonModal from './PriceComparisonModal';
import './PriceList.css';
import { db } from './firebase';
import { collection, getDocs } from 'firebase/firestore';
import googleLogo from '../assets/google.png';

const PriceList = ({ user, onSignIn, onSignOut }) => {
  const [prices, setPrices] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [rarity, setRarity] = useState('');
  const [type, setType] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchPrices = async () => {
      const pricesSnapshot = await getDocs(collection(db, 'items'));
      const pricesList = pricesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPrices(pricesList);
    };
    fetchPrices();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when currentPage changes
  }, [currentPage]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleMinPriceChange = (event) => {
    setMinPrice(event.target.value);
    setCurrentPage(1);
  };

  const handleMaxPriceChange = (event) => {
    setMaxPrice(event.target.value);
    setCurrentPage(1);
  };

  const handleRarityChange = (event) => {
    setRarity(event.target.value);
    setCurrentPage(1);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
    setCurrentPage(1);
  };

  const filteredPrices = prices.filter(item => {
    const matchesName = item.name.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesMinPrice = minPrice === '' || item.price >= parseFloat(minPrice);
    const matchesMaxPrice = maxPrice === '' || item.price <= parseFloat(maxPrice);
    const matchesRarity = rarity === '' || item.rarity.toLowerCase().includes(rarity.toLowerCase());
    const matchesType = type === '' || item.type.toLowerCase().includes(type.toLowerCase());
    return matchesName && matchesMinPrice && matchesMaxPrice && matchesRarity && matchesType;
  });

  const totalPages = Math.ceil(filteredPrices.length / itemsPerPage);
  const currentPrices = filteredPrices.slice((currentPage - 1) * itemsPerPage, (currentPage * itemsPerPage));

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSignIn = async () => {
    setError(null);
    try {
      await onSignIn();
    } catch (error) {
      if (error.code === 'auth/popup-closed-by-user') {
        setError('Sign-in popup was closed before completing. Please try again.');
      } else {
        setError('An error occurred during sign-in. Please try again.');
      }
    }
  };

  return (
    <div className="price-list-container">
      <div className="filters">
        <input
          type="text"
          placeholder="Search rares..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input"
        />
        <input
          type="number"
          placeholder="Min price"
          value={minPrice}
          onChange={handleMinPriceChange}
          className="price-input"
        />
        <input
          type="number"
          placeholder="Max price"
          value={maxPrice}
          onChange={handleMaxPriceChange}
          className="price-input"
        />
        <input
          type="text"
          placeholder="Rarity (e.g. common, rare)"
          value={rarity}
          onChange={handleRarityChange}
          className="rarity-input"
        />
        <select
          value={type}
          onChange={handleTypeChange}
          className="type-dropdown"
        >
          <option value="">Select Type</option>
          <option value="super rares">Super Rares</option>
          <option value="hc rares">HC Rares</option>
          <option value="catalogue rares">Catalogue Rares</option>
          <option value="funky friday">Funky Friday</option>
        </select>
        {user ? (
          <button className="auth-button signout-button" onClick={onSignOut}>Sign Out</button>
        ) : (
          <button className="auth-button" onClick={handleSignIn}>
            <img src={googleLogo} alt="Google Logo" className="google-logo" />
            Sign In with Google to Vote
          </button>
        )}
      </div>
      {error && <p className="error-message">{error}</p>}
      <button className="calculate-button" onClick={toggleModal}>
        Calculate Prices
      </button>
      <div className="price-list">
        {currentPrices.map(item => (
          <PriceItem key={item.id} item={item} user={user} />
        ))}
      </div>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`pagination-button ${index + 1 === currentPage ? 'active' : ''}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
      {isModalOpen && (
        <PriceComparisonModal prices={prices} onClose={toggleModal} />
      )}
    </div>
  );
};

export default PriceList;
