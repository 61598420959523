// src/components/PriceItem.js
import React, { useState, useEffect } from 'react';
import './PriceItem.css';
import hcSofaImage from '../assets/HC-Sofa.jpg';
import colaMachineImage from '../assets/Cola-Machine.jpg';
import { db } from './firebase';
import { doc, getDoc, setDoc, updateDoc, addDoc, collection } from 'firebase/firestore';

const PriceItem = ({ item, user }) => {
  const [votes, setVotes] = useState(item.votes || 0);
  const [hasVoted, setHasVoted] = useState(false);
  const [showSuggestionFields, setShowSuggestionFields] = useState(false);
  const [creditSuggestion, setCreditSuggestion] = useState('');
  const [hcSofaSuggestion, setHcSofaSuggestion] = useState('');
  const [colaMachineSuggestion, setColaMachineSuggestion] = useState('');

  useEffect(() => {
    const checkIfVoted = async () => {
      if (user) {
        const voteDocRef = doc(db, 'votes', `${user.uid}_${item.id}`);
        const voteDoc = await getDoc(voteDocRef);
        setHasVoted(voteDoc.exists());
      }
    };
    checkIfVoted();
  }, [item.id, user]);

  const handleVote = async () => {
    if (!user) {
      alert("Please sign in to vote.");
      return;
    }

    if (hasVoted) {
      alert("You have already voted for this item.");
      return;
    }

    try {
      const voteDocRef = doc(db, 'votes', `${user.uid}_${item.id}`);
      await setDoc(voteDocRef, {
        itemId: item.id,
        userId: user.uid,
        userEmail: user.email, // Add the email to the document
        votedAt: new Date()
      });

      const itemDocRef = doc(db, 'items', item.id);
      await updateDoc(itemDocRef, {
        votes: votes + 1
      });

      setVotes(votes + 1);
      setHasVoted(true);
      setShowSuggestionFields(true);
    } catch (error) {
      console.error("Error voting: ", error);
      alert("Error voting. Please try again.");
    }
  };

  const handleSuggestionSubmit = async () => {
    if (!creditSuggestion || !hcSofaSuggestion || !colaMachineSuggestion) {
      alert("Please fill in all suggestion fields.");
      return;
    }

    try {
      const suggestionsRef = collection(db, 'suggestions');
      await addDoc(suggestionsRef, {
        itemId: item.id,
        userId: user.uid,
        userEmail: user.email, // Add the email to the document
        creditSuggestion: parseFloat(creditSuggestion),
        hcSofaSuggestion: parseFloat(hcSofaSuggestion),
        colaMachineSuggestion: parseFloat(colaMachineSuggestion),
        suggestedAt: new Date()
      });

      alert("Thank you for your suggestions!");
      setShowSuggestionFields(false);
    } catch (error) {
      console.error("Error submitting suggestions: ", error);
      alert("Error submitting suggestions. Please try again.");
    }
  };

  return (
    <div className="price-item">
      <img src={require(`../${item.image}`)} alt={item.name} className="price-item-image" />
      <div className="price-item-details">
        <h2>{item.name}</h2>
        <div className="item-price-credits">
          <span>{item.price} Habbo Credits</span>
        </div>
        <div className="item-price">
          <img src={hcSofaImage} alt="HC Sofa" className="small-icon" />
          <span>{item.worthInHCSofas}</span>
          <img src={colaMachineImage} alt="Cola Machine" className="small-icon" />
          <span>{item.worthInColaMachines}</span>
        </div>
        <p>Rarity: {item.rarity}</p>
        <p>Type: {item.type}</p>
        <button onClick={handleVote} disabled={hasVoted} className="vote-button">
          Vote to change price
        </button>
        <p>Votes: {votes}</p>
        {showSuggestionFields && (
          <div className="suggestion-fields">
            <input
              type="number"
              placeholder="Suggested Credits"
              value={creditSuggestion}
              onChange={(e) => setCreditSuggestion(e.target.value)}
              className="suggestion-input"
            />
            <input
              type="number"
              placeholder="Suggested HC Sofas"
              value={hcSofaSuggestion}
              onChange={(e) => setHcSofaSuggestion(e.target.value)}
              className="suggestion-input"
            />
            <input
              type="number"
              placeholder="Suggested Cola Machines"
              value={colaMachineSuggestion}
              onChange={(e) => setColaMachineSuggestion(e.target.value)}
              className="suggestion-input"
            />
            <button onClick={handleSuggestionSubmit} className="submit-suggestion-button">
              Submit Suggestions
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceItem;
