// src/components/News.js
import React from 'react';
import './News.css';

const newsData = [
  { id: 2, text: " HabboPrices might have incorrect prices. Vote and suggest prices to change them.", date: "2024-07-02"},
  { id: 1, text: " HabboPrices First Launch Today!", date: "2024-07-01" },
  
  // Add more news items as needed
];

const News = () => {
  return (
    <div className="news">
      <h2>Recent Updates</h2>
      <ul>
        {newsData.map(news => (
          <li key={news.id}>
            <span className="news-date">{news.date}</span>
            <span className="news-text">{news.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default News;
